<template>
  <div class="content_box">
    <div class="inner">
      <Card>
        <div class="main">
          <img src="@/assets/images/dialog_false.png" alt />
          <div>
            {{ $t('register.authority.dialog1') }}
            <br />
            <br />
            {{ $t('register.authority.dialog2') }}
          </div>

          <router-link :to="{ name: 'register' }" class="el-button purple_button">
            {{ $t('register.authority.openLiveAcc') }}
          </router-link>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card';

export default {
  components: { Card }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/authority.scss';
</style>
